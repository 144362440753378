(() => {
	const siteName = 'default-package_2023_vanilla';
	const htmlHasClass = (_class) => { return document.documentElement.classList.contains(_class); };
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			header: {
				desktop: 90,
				sp: 70
			}
		},
		localDecision() {
			const regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				ajaxArray.push(path);
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {
					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});
						console.log('Succeeded to read the include file!:', position);
					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery() {
			let vars = [];
			let hash = null;
			let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			hashes.forEach(ele => {
				hash = ele.split('=');
				hash[1] = (hash[1].indexOf('#') !== -1) ? hash[1].split('#')[0] : hash[1];
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			});
			return vars;
		},
		nextToggle() {
			const switchElements = document.querySelectorAll('.js-toggle');
			switchElements.forEach(switchElement => {
				switchElement.addEventListener('click', e => {
					e.currentTarget.classList.toggle(a);
					let toggleBlock = e.currentTarget.nextElementSibling;
					toggleBlock.classList.toggle(a);
					if (toggleBlock.style.height) {
						toggleBlock.style.height = null;
						toggleBlock.style.visibility = 'hidden';
					} else {
						toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
						toggleBlock.style.visibility = 'visible';
					}
				});
				// 初期値の設定
				// js-toggle-openがある場合は開いた状態(height: 要素の高さpx)
				// ない場合は閉じた状態(height: 0px)
				// let toggleBlock = switchElement.nextElementSibling;
				// if (switchElement.classList.contains('js-toggle-open')) {
				// 	switchElement.classList.add(a);
				// 	toggleBlock.classList.add(a);
				// 	toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
				// 	toggleBlock.style.visibility = 'visible';
				// }else {
				// 	toggleBlock.style.height = '0px';
				// }
				// switchElement.addEventListener('click', e => {
				// 	e.currentTarget.classList.toggle(a);
				// 	toggleBlock.classList.toggle(a);
				// 	if (parseInt(toggleBlock.style.height) > 0) {
				// 		toggleBlock.style.height = 0;
				// 		toggleBlock.style.visibility = 'hidden';
				// 	} else {
				// 		toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
				// 		toggleBlock.style.visibility = 'visible';
				// 	}
				// });
			});

			window.addEventListener('resize', ()=> {
				switchElements.forEach(switchElement => {
					let toggleBlock = switchElement.nextElementSibling;
					if (parseInt(toggleBlock.style.height) > 0) {
						toggleBlock.style.height = 'auto';
						toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
					}
				})
			})
		},
		tabSwitch() {
			let $tabWrap = document.querySelectorAll('.js-tabWrap');
			let $tab = document.querySelectorAll('.js-tab');
			let $content = document.querySelectorAll('.js-tab-content');
			if ($tab.length) {
				// ハッシュタグで絞り込み
				if (location.hash) {
					let hash = location.hash.split('#')[1];
					$tab.forEach(ele => {
						ele.classList.remove(a);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.add(a);
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}
				// ?tab=パラメータで絞り込み
				function getParam(name, url) {
					if (!url) url = window.location.href;
					name = name.replace(/[\[\]]/g, "\\$&");
					var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
						results = regex.exec(url);
					if (!results) return null;
					if (!results[2]) return '';
					return decodeURIComponent(results[2].replace(/\+/g, " "));
				}
				if(getParam('tab')) {
					$tab.forEach(ele => {
						ele.classList.remove(a);
						if (ele.getAttribute('data-target').match(getParam('tab'))) {
							ele.classList.add(a);
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(getParam('tab'))) {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}
				$tabWrap.forEach(wrapele => {
					wrapele.querySelectorAll('.js-tab').forEach(ele => {
						ele.addEventListener('click', () => {
							wrapele.querySelectorAll('.js-tab').forEach(ele2 => { ele2.classList.remove(a); })
							ele.classList.add(a);
							wrapele.querySelectorAll('.js-tab-content').forEach(ele => {
								ele.classList.remove(a);
								ele.classList.remove(v);
							});
							let $target;
							let target = ele.getAttribute('data-target');
							if (target === 'all') {
								$target = $content;
							} else {
								$target = document.querySelectorAll('[data-target="' + target + '"]');
							}
							$target.forEach(ele => {
								ele.classList.add(a);
								setTimeout(() => { ele.classList.add(v); }, 200);
							});
						});
					});
				});
			}
		},
		easeScroll() {
			const scrollToObject = (_self) => {
				let hash = _self.currentTarget.href.split('#')[1];
				let pos = hash === 'pagetop' ? 0 : document.getElementById(hash).getBoundingClientRect().top;
				let offset = hash === 'pagetop' ? 0 : window.scrollY;
				pos += offset;

				if(!(location.pathname.split('/')[1] == 'history')) {
					if (hash !== 'pagetop') {
						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos = pos - FUNCTIONS.va.header.sp;
						} else {
							pos = pos - FUNCTIONS.va.header.desktop;
						}
					}
				} else {
					if (hash !== 'pagetop') {
						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos = pos - (FUNCTIONS.va.header.sp + 70);;
						} else {
							pos = pos - (FUNCTIONS.va.header.desktop + 90);;
						}
					}
				}
				window.scrollTo({ top: pos, behavior: 'smooth' });
				// toggleだった場合開く
				if(document.getElementById(_self.currentTarget.href.split('#')[1]).classList.contains('_js-scrollopen')) {
					document.getElementById(_self.currentTarget.href.split('#')[1]).classList.add(a);
					let toggleBlock = document.getElementById(_self.currentTarget.href.split('#')[1]).nextElementSibling;
					toggleBlock.classList.add(a);
					if (toggleBlock.style.height) {
						toggleBlock.style.height = null;
						toggleBlock.style.visibility = 'hidden';
					} else {
						toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
						toggleBlock.style.visibility = 'visible';
					}
				}
			}
			// フォーカス用関数
			const scrollFocus = (_hash) => {
				let hash = _hash;
				if (hash !== 'pagetop') {
					const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
					const focusableElements = Array.from(
						document.getElementById(hash).querySelectorAll(focusableElementsSelector)
					);
					if(focusableElements.length) {
						focusableElements[0].focus();
					}
				}
			};
			// スクロール完了後に実行する関数
			let scrollResolveTimeout;
			const waitForScrollComplete = (_hash) => {
			  return new Promise((resolve) => {
				const scrollTimeout = setTimeout(() => {
				  resolve(true);
				}, 100);
				const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
					clearTimeout(scrollTimeout);
					clearTimeout(scrollResolveTimeout);
					scrollResolveTimeout = setTimeout(function () {
						scrollFocus(_hash);
						removeEventListener('scroll', fn);
						resolve(true);
					}, 100);
				});
			  });
			};
			document.querySelectorAll('a[rel="scroll"]').forEach((ele) => {
				ele.addEventListener('click', async (e) => {
					e.preventDefault();
					if (/#/.test(e.currentTarget.href)) {
						scrollToObject(e);
						let hash = e.currentTarget.href.split('#')[1];
						await waitForScrollComplete(hash);
						return false;
					}
				});
			});
		},
		easeScrollHash() {
			const hash = location.hash;
			// マーズフラッグ検索結果ページ以外で発火
			if (!(document.querySelectorAll('.js-marsflag-result').length)) {
				if (hash) {
					// フォーカス用関数
					const scrollFocus = (_hash) => {
						let hash = _hash;
						if (hash !== 'pagetop') {
							const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
							const focusableElements = Array.from(
								document.getElementById(hash).querySelectorAll(focusableElementsSelector)
							);
							if(focusableElements.length) {
								focusableElements[0].focus();
							}
						}
					}
					// スクロール完了後に実行する関数
					let scrollResolveTimeout;
					const waitForScrollComplete = (_hash) => {
						return new Promise((resolve) => {
							const scrollTimeout = setTimeout(() => {
							resolve(true);
							}, 100);
							const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
								clearTimeout(scrollTimeout);
								clearTimeout(scrollResolveTimeout);
								scrollResolveTimeout = setTimeout(function () {
									scrollFocus(_hash);
									removeEventListener('scroll', fn);
									resolve(true);
								}, 100);
							});
						});
					}
					window.addEventListener('load', async () => {
						document.documentElement.style.display = 'none';
						document.body.style.display = 'none';

						setTimeout(() => {
							document.documentElement.style.display = 'block';
							document.body.style.display = 'block';const $elements = document.querySelectorAll( '[data-mh]' );

							const groups = new Map();
				
							$elements.forEach( ele => { ele.style.height = 'auto'; } ); // 初期化
				
							$elements.forEach( ele => {
								const attributeName = ele.getAttribute( 'data-mh' );
								if ( !groups.has( attributeName ) ) {
									groups.set( attributeName, [] );
								}
								groups.get( attributeName ).push( ele );
							} );
							groups.forEach( group => {
								const attributeGroups = new Map();
								group.forEach( ele => {
									const attributeName = ele.getAttribute( 'data-mh' );
									const topPosition = ele.getBoundingClientRect().top;
									const key = attributeName + '_' + topPosition;
									if ( !attributeGroups.has( key ) ) {
										attributeGroups.set( key, [] );
									}
									attributeGroups.get( key ).push( ele );
								} );
								attributeGroups.forEach( (item) => {
									const maxHeight = Math.max( ...item.map( ele => ele.offsetHeight ) );
									item.forEach( (ele2) => {
										ele2.style.height = `${maxHeight}px`;
									});
								});
							} );

							let pos = document.getElementById(hash.split('#')[1]).getBoundingClientRect().top;

							if(!(location.pathname.split('/')[1] == 'history')) {
								if (hash !== 'pagetop') {
									if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
										pos = pos - FUNCTIONS.va.header.sp;
									} else {
										pos = pos - FUNCTIONS.va.header.desktop;
									}
								}
							} else {
								if (hash !== 'pagetop') {
									if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
										pos = pos - (FUNCTIONS.va.header.sp + 70);;
									} else {
										pos = pos - (FUNCTIONS.va.header.desktop + 90);;
									}
								}
							}
							window.scrollTo({ top: pos, behavior: 'smooth' });
						}, 100);
						await waitForScrollComplete(hash);
					});
				}
			}
		},
		setImgAttrWidthHeight() {
			const getImg = (_src) => {
				return new Promise((resolve, reject) => {
					const image = new Image();
					image.src = _src;
					image.onload = () => { resolve(image); }
					image.onerror = (error) => { reject(error); }
				});
			};

			const imgs = document.getElementsByTagName('img');

			for (const img of imgs) {
				const src = img.getAttribute('src');
				getImg(src).then((res) => {
					if (!img.hasAttribute('width')) {
						img.setAttribute('width', res.width);
					}
					if (!img.hasAttribute('height')) {
						img.setAttribute('height', res.height);
					}
				})
					.catch((error) => { console.log(error); });
			}
		},
		setModal() {
			const createModal = (_appendHTML,_target) => {
				const $container = document.createElement('div');
				$container.className = 'mod-modal-overlay';
				$container.innerHTML = _appendHTML;
				document.body.appendChild($container);

				$container.classList.add(a);
				setTimeout(() => {
					$container.classList.add(v);
					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modalContent a').forEach((ele) => {
						ele.addEventListener('click', (e) => {
							$container.classList.remove(v);
							setTimeout(() => {
								$container.classList.remove(a);
								$container.remove();
								_target.focus();
							}, 400);
						});
					});
					window.addEventListener("keydown", function (event) {
						if(document.querySelectorAll('.mod-modal-overlay').length) {
							// タブキーが押された時
							if (event.key === "Tab") {
								event.preventDefault();
								// モーダル要素内のフォーカス可能な要素の一覧を取得
								const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
								const focusableElements = Array.from(
								document.querySelector('.mod-modal-overlay').querySelectorAll(focusableElementsSelector)
								);
								// 現在のフォーカス位置を取得
								const focusedItemIndex = focusableElements.indexOf(document.activeElement);
								// shiftキーと同時に押されてた場合
								if (event.shiftKey) {
								if (focusedItemIndex === 0) {
									// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
									focusableElements[focusableElements.length - 1].focus();
								} else {
									// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
									focusableElements[focusedItemIndex - 1].focus();
								}
								} else {
								if (focusedItemIndex === focusableElements.length - 1) {
									// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
									focusableElements[0].focus();
								} else {
									// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
									focusableElements[focusedItemIndex + 1].focus();
								}
								}
							}
							// ESCキーが押された時
							if (event.key === "Escape") {
								event.preventDefault();
								$container.classList.remove(v);
								setTimeout(() => {
									$container.classList.remove(a);
									$container.remove();
									_target.focus();
								}, 400);
							}
						}
					});
				});
			};

			document.querySelectorAll('.mod-modal').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let targetKey = e.currentTarget.getAttribute('data-target');
					let $content = document.querySelector(`.mod-modalContent[data-target="${targetKey}"]`);
					if ($content.outerHTML) {
						let appendHTML = `<div class="mod-modal-closeArea"></div>
							<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
							${$content.outerHTML}`;
						createModal(appendHTML,e.currentTarget);
					}
					return false;
				});
			});

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let appendHTML = `<div class="mod-modal-closeArea"></div>
						<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
						${e.currentTarget.querySelector('img').outerHTML}`;
					createModal(appendHTML,e.currentTarget);
					return false;
				});
			});

			// ヘッダーサイト検索モーダル
			if (document.querySelector('.js-header-search')) {
				document.querySelector('.js-header-search').addEventListener('click', (e) => {
					document.querySelector('.header-search-wrap').classList.add(a);
					window.addEventListener("keydown", function (event) {
						// タブキーが押された時
						if (event.key === "Tab") {
							event.preventDefault();
							// モーダル要素内のフォーカス可能な要素の一覧を取得
							const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
							const focusableElements = Array.from(
							document.querySelector('.header-search-wrap').querySelectorAll(focusableElementsSelector)
							);
							// 現在のフォーカス位置を取得
							const focusedItemIndex = focusableElements.indexOf(document.activeElement);
							// shiftキーと同時に押されてた場合
							if (event.shiftKey) {
							if (focusedItemIndex === 0) {
								// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
								focusableElements[focusableElements.length - 1].focus();
							} else {
								// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
								focusableElements[focusedItemIndex - 1].focus();
							}
							} else {
							if (focusedItemIndex === focusableElements.length - 1) {
								// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
								focusableElements[0].focus();
							} else {
								// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
								focusableElements[focusedItemIndex + 1].focus();
							}
							}
						}
						// ESCキーが押された時
						if (event.key === "Escape") {
							event.preventDefault();
							document.querySelector('.header-search-wrap').classList.remove(a);
							setTimeout(() => {
								document.querySelector('.js-header-search').focus();
							}, 400);
						}
					});
					return false;
				});
				document.querySelector('.header-search-overlay').addEventListener('click', (e) => {
					document.querySelector('.header-search-wrap').classList.remove(a);
				});
			}
		},
		matchHeight() {
			const $elements = document.querySelectorAll( '[data-mh]' );
			const groups = new Map();

			$elements.forEach( ele => { ele.style.height = 'auto'; } ); // 初期化

			$elements.forEach( ele => {
				const attributeName = ele.getAttribute( 'data-mh' );
				if ( !groups.has( attributeName ) ) {
					groups.set( attributeName, [] );
				}
				groups.get( attributeName ).push( ele );
			} );
			groups.forEach( group => {
				const attributeGroups = new Map();
				group.forEach( ele => {
					const attributeName = ele.getAttribute( 'data-mh' );
					const topPosition = ele.getBoundingClientRect().top;
					const key = attributeName + '_' + topPosition;
					if ( !attributeGroups.has( key ) ) {
						attributeGroups.set( key, [] );
					}
					attributeGroups.get( key ).push( ele );
				} );
				attributeGroups.forEach( (item) => {
					const maxHeight = Math.max( ...item.map( ele => ele.offsetHeight ) );
					item.forEach( (ele2) => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			} );
		},
		inViewAnimation() {
			const target = document.querySelectorAll('.js-fade');
			const options = {
				root: null,
				rootMargin: '-30% 0px',
				threshold: 0
			};
			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add(v);
					}
				});
			};
			const observer = new IntersectionObserver(callback, options);

			target.forEach(ele => {
				observer.observe(ele);
			});
		},
		headerHamburger() {
			if(document.querySelectorAll('.header-hamburger').length) {

				const $hamburger = document.querySelector('.header-hamburger');
				const $nav = document.querySelector('.header-nav-sp');
				const $container = document.querySelector('.container');
				const $header = document.querySelector('.header');
				let scrollTopNow;
	
				const fixedOnContainer = () => {
					scrollTopNow = window.scrollY;
					$container.style.position = 'fixed';
					$container.style.width = '100%';
					$container.style.top = -1 * scrollTopNow;
					$container.style.left = 0;
					document.body.classList.add(f);
				};
				const fixedOffContainer = () => {
					$container.style.position = 'static';
					document.body.classList.remove(f);
					window.scrollTo({ top: scrollTopNow });
				};
				const resetLayer = () => {
					const $layer = document.querySelectorAll('[data-pageName]');
	
					$layer.forEach(layer => {
						layer.classList.remove(a);
						layer.classList.remove(v);
					});
				}
	
				if ($hamburger) {
					$hamburger.addEventListener('click', (e) => {
						e.currentTarget.classList.toggle(a);
						if ($nav.classList.contains(a)) {
							$nav.classList.remove(v);
							$nav.classList.remove(a);
							$header.classList.remove(a);
							$hamburger.ariaLabel = 'メニューを開く'
							fixedOffContainer();
							resetLayer();
						} else {
							const $layer1 = document.querySelector('.header-nav-sp-layer1');
							$layer1.classList.add(a);
							$layer1.classList.add(v);
							fixedOnContainer();
							$nav.classList.add(a);
							$header.classList.add(a);
							$hamburger.ariaLabel = 'メニューを閉じる'
							setTimeout(() => {
								$nav.classList.add(v);
							});
						}
					});
				}
			}
		},
		fileSize() {
			const bytesToSize = (bytes) => {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
			if(document.querySelectorAll('.js-fileSize').length) {
				document.querySelectorAll('.js-fileSize').forEach(function (pdfElement) {
					pdfElement.innerText = bytesToSize(pdfElement.innerText);
				});
			}
		},
		megaMenu() {
			if(document.querySelectorAll('.header').length) {
				// スクロール時のヘッダー高さ変更
				let scroll = window.scrollY;
				let $headerInner = document.querySelector('.header');
				if (scroll > 0) {
					$headerInner.classList.add('is-scroll')
				} else {
					$headerInner.classList.remove('is-scroll')
				}
				window.addEventListener("scroll", function () {
					scroll = window.scrollY;
					$headerInner = document.querySelector('.header');
					if (scroll > 0) {
						$headerInner.classList.add('is-scroll')
					} else {
						$headerInner.classList.remove('is-scroll')
					}
				});
				// メガメニューの高さ調整
				// const $layer2 =  document.querySelectorAll('.header-nav-layer2')
				// $layer2.forEach(layer2 => {
				// 	const $layer3 = layer2.querySelectorAll('.header-nav-layer3')
				// 	let layer2Height = layer2.clientHeight;
				// 	$layer3.forEach(layer3 => {
				// 		const layer3Height = layer3.clientHeight;
				// 		if (layer2Height < layer3Height) {
				// 			layer2Height = layer3Height;
				// 			layer2.style.height = layer2Height + "px";
				// 		}
				// 	})
				// 	$layer3.forEach(layer3 => {
				// 		layer3.style.height = '100%';
				// 	})
				// })
				// メガメニューのフォーカス時の動作
				const $layer1Itmes = document.querySelectorAll('.header-nav-layer1-item')
				$layer1Itmes.forEach(layer1 => {
					layer1.addEventListener('mouseover', function() {
						// メガメニューの高さ調整
						const $layer2 =  document.querySelectorAll('.header-nav-layer2')
						$layer2.forEach(layer2 => {
							const $layer3 = layer2.querySelectorAll('.header-nav-layer3')
							let layer2Height = layer2.clientHeight;
							$layer3.forEach(layer3 => {
								const layer3Height = layer3.clientHeight;
								// console.log(layer2 , layer3);
								if (layer2Height < layer3Height) {
									layer2Height = layer3Height;
									layer2.style.height = layer2Height + "px";
								}
							})
							$layer3.forEach(layer3 => {
								layer3.style.height = '100%';
							})
						})
					});
					layer1.children[0].addEventListener("keydown", (e) => {
						// Enterキーでメガメニューの開閉
						if( e.keyCode === 13 ){
							if (layer1.children[0].classList.contains(a)) {
								layer1.children[0].classList.remove(a);
								layer1.children[1].classList.remove(a);
							}else {
								layer1.children[0].classList.add(a);
								layer1.children[1].classList.add(a);
								// メガメニューの高さ調整
								const $layer2 =  document.querySelectorAll('.header-nav-layer2')
								$layer2.forEach(layer2 => {
									const $layer3 = layer2.querySelectorAll('.header-nav-layer3')
									let layer2Height = layer2.clientHeight;
									$layer3.forEach(layer3 => {
										const layer3Height = layer3.clientHeight;
										if (layer2Height < layer3Height) {
											layer2Height = layer3Height;
											layer2.style.height = layer2Height + "px";
										}
									})
									$layer3.forEach(layer3 => {
										layer3.style.height = '100%';
									})
								})
							}
						}
						// ESCキーでメガメニューを閉じる
						if( e.keyCode === 27 ){
							if (layer1.children[0].classList.contains(a)) {
								layer1.children[0].classList.remove(a);
								layer1.children[1].classList.remove(a);
							}
						}
						// メガメニューを開いた状態で、一つ前の項目に移動した場合、メガメニューを閉じる
						if (event.shiftKey) {
							if( e.keyCode === 9 ){
								if (layer1.children[0].classList.contains(a)) {
									layer1.children[0].classList.remove(a);
									layer1.children[1].classList.remove(a);
								}
							}
						}
					})
					if (layer1.children[1]) {
						layer1.children[1].addEventListener("keydown", (e) => {
							// ESCキーでメガメニューを閉じる
							if( e.keyCode === 27 ){
								if (layer1.children[0].classList.contains(a)) {
									layer1.children[0].classList.remove(a);
									layer1.children[1].classList.remove(a);
								}
							}
						})
					}
					layer1.children[0].addEventListener('mouseover', () => {
						// フォーカスでメガメニューを開いた状態で、ホバーさせたときに、フォーカスで操作した内容をリセットする
						const $link = document.querySelector('.header-nav').querySelectorAll('a')
						$link.forEach(ele => {
							ele.blur()
							layer1.children[0].classList.remove(a);
							if(layer1.children[1]){
								layer1.children[1].classList.remove(a);
							}
						})
					})

					if (layer1.children[1]) {
						layer1.children[1].querySelectorAll('a').forEach((link,index,array) => {
							// 3階層目を持つ項目にフォーカスしたとき、3階層目を開く
							link.addEventListener('focus', () => {
								if(link.parentElement.className === "__have_child") {
									link.parentElement.parentElement.classList.add(a)
								}
								if(link.parentElement.className === "header-nav-layer3-item") {
									link.parentElement.parentElement.parentElement.classList.add(a)
								}
							})
							link.addEventListener('blur', () => {
								// 3階層目からフォーカスが離れたとき、3階層目を閉じる
								if(link.parentElement.className === "__have_child") {
									link.parentElement.parentElement.classList.remove(a)
								}
								if(link.parentElement.className === "header-nav-layer3-item") {
									link.parentElement.parentElement.parentElement.classList.remove(a)
								}
							})
							link.addEventListener('mouseover', () => {
								// フォーカスでメガメニューを開いた状態で、ホバーさせたときに、フォーカスで操作した内容をリセットする
								const $link = document.querySelector('.header-nav').querySelectorAll('a')
								$link.forEach(ele => {
									ele.blur()
									layer1.children[0].classList.remove(a);
									layer1.children[1].classList.remove(a);
								})
							})
							if (index === 0) {
								// メガメニューの最初の項目にフォーカス状態で、Shift+Tabを押したとき、メガメニューを閉じる
								link.addEventListener("keydown", (e) => {
									if (event.shiftKey) {
										if( e.keyCode === 9 ){
											if (layer1.children[0].classList.contains(a)) {
												layer1.children[0].classList.remove(a);
												layer1.children[1].classList.remove(a);
											}
										}
									}
								})
							}
							if (index === (array.length - 1)) {
								// メガメニューの最後の項目にフォーカス状態で、Tabを押したとき、メガメニューを閉じる
								link.addEventListener("keydown", (e) => {
									if( e.keyCode === 9 ){
										if (layer1.children[0].classList.contains(a)) {
											layer1.children[0].classList.remove(a);
											layer1.children[1].classList.remove(a);
										}
									}
								})
							}
						})
					}
				})
				// グロナビカレント
				document.querySelectorAll('.js-header-current').forEach(item1 => {
					if(item1.dataset.current == location.pathname.split('/')[1]) {
						item1.classList.add('is-current');
					}
				});
			}
		},
		headerSpMenu() {
			const $layer = document.querySelectorAll('[data-pageName]');
			const $nextlayerBtn = document.querySelectorAll( '[data-nextPageName]' );
			const $returnBtn = document.querySelectorAll('[data-prevPageName]');

			const resetLayer = () => {
				$layer.forEach(layer => {
					layer.classList.remove(a);
					layer.classList.remove(v);
				});
			}
			resetLayer()

			$nextlayerBtn.forEach(nextlayerBtn => {
				nextlayerBtn.addEventListener('click', ()=> {
					const nextLayer = nextlayerBtn.getAttribute('data-nextPageName');
					resetLayer()
					$layer.forEach( layer => {
						if(layer.getAttribute('data-pageName') === nextLayer) {
							layer.classList.add(a);
							setTimeout(() => {
								layer.classList.add(v);
							}, 300);
						}
					})
				})
			})
			$returnBtn.forEach(returnBtn => {
				returnBtn.addEventListener('click', ()=> {
					const prevLayer = returnBtn.getAttribute('data-prevPageName');
					resetLayer()
					$layer.forEach( layer => {
						if(layer.getAttribute('data-pageName') === prevLayer) {
							layer.classList.add(a);
							setTimeout(() => {
								layer.classList.add(v);
							}, 300);
						}
					})
				})
			})
			if(document.querySelectorAll('.header-nav-sp-close').length) {

				document.querySelector('.header-nav-sp-close').addEventListener('click', ()=> {
					const $hamburger = document.querySelector('.header-hamburger');
					const $nav = document.querySelector('.header-nav-sp');
					const $container = document.querySelector('.container');
					const $header = document.querySelector('.header');
					let scrollTopNow;
	
					const fixedOffContainer = () => {
						$container.style.position = 'static';
						document.body.classList.remove(f);
						window.scrollTo({ top: scrollTopNow });
					};
					$hamburger.classList.remove(a);
					$nav.classList.remove(v);
					$nav.classList.remove(a);
					$header.classList.remove(a);
					$hamburger.ariaLabel = 'メニューを開く'
					fixedOffContainer();
	
	
					const resetLayer = () => {
						$layer.forEach(layer => {
							layer.classList.remove(a);
							layer.classList.remove(v);
						});
					}
					resetLayer()
					// $layer1.classList.add(a);
					// $layer1.classList.add(v);
				})
			}
		},
		share() {
			const sharelist = document.querySelectorAll('.mod-share')
			if (sharelist.length) {
				var pageUrl = document.URL;
				var shareUrl = "";
				var sharePagetitle = encodeURIComponent(document.title);
				sharelist.forEach(elem => {
					if (elem.querySelector('._facebook')) {
						// facebook
						shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl;
						elem.querySelector('._facebook').setAttribute('href', shareUrl);
					}
					if (elem.querySelector('._x')) {
						// X
						shareUrl = 'https://twitter.com/share?url=' + pageUrl + '&text=' + sharePagetitle;
						elem.querySelector('._x').setAttribute('href', shareUrl);
					}
					if (elem.querySelector('._linkedin')) {
						// Linkedin
						shareUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=' + pageUrl;
						elem.querySelector('._linkedin').setAttribute('href', shareUrl);
					}
				});
			}
		},
		urlcurrent() {
			const urlcurrent = document.querySelectorAll('.js-urlcurrent')
			if (urlcurrent.length) {
				urlcurrent.forEach(elem => {
					if(elem.pathname == location.pathname) {
						elem.classList.add(a);
						elem.classList.add(v);
					}
				});
			}
		},
		pageTop() {
			let footerbottom = document.querySelector('.footer-bottom');
			let $pagetop = document.querySelector('.footer-bottom-to_top');

			if ($pagetop) {
				window.addEventListener('scroll', (e) => {
					if (window.scrollY > '100') {
						$pagetop.classList.add(v);
					} else {
						$pagetop.classList.remove(v);
					}
				});
			}
		},
		loadDelayScript() {
			let _this = this;
			_this.nextToggle();
			_this.tabSwitch();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.setImgAttrWidthHeight();
			_this.setModal();
			_this.inViewAnimation();
			_this.headerHamburger();
			_this.fileSize();
			_this.megaMenu();
			_this.headerSpMenu();
			_this.share();
			_this.urlcurrent();
			_this.pageTop();
			let resizeTime = 0;
			window.addEventListener('load', () => { _this.matchHeight(); });
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});
		}
	};

	document.addEventListener('DOMContentLoaded', () => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})();
